/**
 * @description The main application class
 * @date 2023-03-25
 * @class MyNextBigChapter
 */
class MyNextBigChapter {
  /**
   * Creates an instance of MyNextBigChapter.
   * @date 2023-03-25
   * @memberof MyNextBigChapter
   */
  constructor() {
    this.init();
  }

  /**
   * @description A UI bound utility to format dates as Mmm, yyyy - Mmm, yyyy or [Current]
   * @date 2023-03-26
   * @param {Date} dateStarted:   The date the experience or education started
   * @param {Date} dateFinished:  The date the experience or education finished
   * @return {String}:            A formatted string of the dates
   * @memberof MyNextBigChapter
   */
  formatDates(dateStarted, dateFinished) {
    // Prefix the calendar icon
    let dateString = `<i class="fa fa-calendar fa-fw w3-margin-right"></i>`;
    // Cache the code to implement the current pill
    const currentPill = `<span class="w3-tag w3-purple w3-round">Current</span>`;
    // Cache the months in short Mmm format
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (!dateStarted && !dateFinished) {
      // Neither date is set, so return the current pill by itself
      return `${dateString} ${currentPill}`;
    } else {
      if (dateStarted) {
        let tmpDate = new Date(dateStarted);
        dateString += months[tmpDate.getMonth() + 1] + ", " + tmpDate.getFullYear() + " - ";

        if (dateFinished) {
          tmpDate = new Date(dateFinished);
          dateString += months[tmpDate.getMonth() + 1] + ", " + tmpDate.getFullYear();
        } else {
          dateString += currentPill;
        }
        return dateString;
      } else {
        // TODO: Decide whether we allow this? Handle it form validation or do some messaging
      }
    }
  }

  /**
   * @description Initialise the application, particularly the event handlers
   * @date 2023-03-25
   * @memberof MyNextBigChapter
   */
  init() {
    // Cache the template elements found on the current page
    this.templates = Object.fromEntries(Array.from(document.querySelectorAll("template")).map((t) => [t.id, t.content]));

    // Add a new employment entry
    document.querySelector("#btnAddExp").addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector("#dlgExpEdit").style.display = "block";
    });

    // Add a new education entry
    document.querySelector("#btnAddEdu").addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector("#dlgEduEdit").style.display = "block";
    });

    // Save an employment entry
    document.querySelector("#btnSaveExperience").addEventListener("click", (e) => {
      // Stop the form from submitting and resetting the page
      e.preventDefault();

      // Get the template from the cache
      const template = this.templates["tmplViewExp"].cloneNode(true);

      // Extract and format the role and organisation data from the dialog box
      const role = `${document.querySelector("#expRole").value || ""}`;
      const organisation = `${document.querySelector("#expOrganisation").value || ""}`;
      const roleOrg = `${role} / ${organisation}`;

      // Extract and format the dates data from the dialog box
      const datesHtml = this.formatDates(
        document.querySelector("#expDateStarted").value,
        document.querySelector("#expDateEnded").value
      );

      // Extract and format the description data from the dialog box
      const description = document.querySelector("#expDescription").value || "";

      // Set the values of the template from the dialog box form
      template.querySelector(".expRoleOrg").innerHTML = roleOrg;
      template.querySelector(".expDates").innerHTML = datesHtml;

      const n = document.createElement("p");
      n.innerHTML = description;
      console.log(n);
      template.querySelector(".experience-view").insertBefore(n, template.querySelector("hr"));

      // Hide the instruction message if it is visible
      document.querySelector("#instExp").style.display = "none";

      // Close the dialog box
      document.querySelector("#dlgExpEdit").style.display = "none";

      // Add the template to the page
      document.querySelector("#cardExp").appendChild(template);
    });

    // Save an education entry
    document.querySelector("#btnSaveEducation").addEventListener("click", (e) => {
      // Stop the form from submitting and resetting the page
      e.preventDefault();

      // Get the template from the cache
      const template = this.templates["tmplViewEdu"].cloneNode(true);

      // Extract and format the role and organisation data from the dialog box
      const institution = `${document.querySelector("#eduInstitution").value || ""}`;
      const qualification = `${document.querySelector("#eduQualification").value || ""}`;
      const instQual = `${institution} / ${qualification}`;

      // Extract and format the dates data from the dialog box
      const datesHtml = this.formatDates(
        document.querySelector("#eduDateStarted").value,
        document.querySelector("#eduDateEnded").value
      );

      // Extract and format the description data from the dialog box
      const description = document.querySelector("#eduDescription").value || "";
      console.log(MyNextBigChapter.utils);
      // Set the values of the template from the dialog box form
      template.querySelector(".eduInstQual").innerHTML = instQual;
      template.querySelector(".eduDates").innerHTML = datesHtml;

      const n = document.createElement("p");
      n.innerHTML = description;
      console.log(n);
      template.querySelector(".education-view").insertBefore(n, template.querySelector("hr"));

      // Hide the instruction message if it is visible
      document.querySelector("#instEdu").style.display = "none";

      // Close the dialog box
      document.querySelector("#dlgEduEdit").style.display = "none";

      // Add the template to the page
      document.querySelector("#cardEdu").appendChild(template);
    });
    // // Add a new education entry
  }
}

// Create an instance of the MyNextBigChapter class when the DOM is ready
document.addEventListener("DOMContentLoaded", () => {
  const myNextBigChapter = new MyNextBigChapter();
});
